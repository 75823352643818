import { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import styles from "@platformAssets/components/Select.module.scss";

const Select = ({
  name,
  handleChange,
  value = null,
  values = {},
  id = null,
  className = null,
  customClassList = null,
  placeholder = null,
  animatePlaceholder = false,
  autoComplete = "off",
  disabled = false,
  errorText = null,
  warningText = null,
  error = false,
  reverse = false,
}) => {
  const [toggleList, setToggleList] = useState(false);
  const dropDownWrapRef = useRef(null);
  const dropDownRef = useRef(null);

  const stylesValues =
    disabled && errorText
      ? `${styles.disabled}`
      : disabled && warningText
        ? `${styles.disabled}`
        : disabled && error
          ? `${styles.disabled} ${styles.errorDisabled}`
          : disabled
            ? `${styles.disabled} `
            : error || errorText
              ? `${styles.error}`
              : "";

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  /**
   * Hide current list if user click outside of element
   * @param {Object} event
   */
  const handleClickOutside = (event) => {
    if (!dropDownRef?.current || !dropDownWrapRef?.current) {
      return;
    }

    if (dropDownWrapRef?.current.contains(event.target)) {
      return;
    }

    setToggleList(false);
  };

  /**
   * Set selected value
   * @param {*} item
   */
  const handleSetValue = (item) => {
    if (typeof handleChange === "function") {
      handleChange(item);
    }

    setToggleList(false);
  };

  /**
   * Open|close dropdown list
   */
  const handleToggleSelect = () => {
    setToggleList(!toggleList);
  };

  /**
   * Ge list of items for dropdown l;ist
   * @param {*} selected Selected value
   * @param {Object} values Selected value
   * @param {boolean} reverse reverse obj bool
   * @returns {*[]}
   */
  const getListItems = (selected = null, values = {}, reverse = false) => {
    const content = [];

    let iteration = 0;
    for (const [key, label] of Object.entries(values)) {
      content.push(
        <li
          onClick={() => handleSetValue(key)}
          key={`d_${name}_${iteration}`}
          className={
            selected === key
              ? styles.selectListItemSelected
              : " " + customClassList
                ? customClassList
                : " "
          }
        >
          {label}
        </li>
      );

      iteration++;
    }
    if (reverse) {
      return content.reverse();
    } else {
      return content;
    }
  };

  // const dropDownContent = useMemo(
  //   () => getListItems(value, values),
  //   [value, values]
  // );

  const dropDownContent = getListItems(value, values, reverse);

  return (
    <>
      <div
        className={`${styles.inputWrapper} ${
          animatePlaceholder ? styles.inputAnimated : ""
        } ${className ?? ""}`}
        ref={dropDownWrapRef}
      >
        <input
          className={stylesValues}
          type="text"
          name={name}
          id={id ?? `${name}Id`}
          autoComplete={autoComplete}
          placeholder={placeholder ?? ""}
          value={value}
          disabled={disabled}
          onClick={handleToggleSelect}
          readOnly={true}
        />
        {animatePlaceholder && (
          <label
            htmlFor={id ?? `${name}Id`}
            className={styles.inputAnimatedPlaceholder}
          >
            {placeholder}
          </label>
        )}
        <p className={styles.errorText}>{errorText}</p>
        <p className={styles.errorWarning}>{warningText}</p>
        {toggleList && (
          <div className={styles.selectListWrap}>
            <ul ref={dropDownRef} className={styles.selectList}>
              {dropDownContent}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
//
// /**
//  * Custom equal method for check props equal mor dip
//  * @param {Object} prevProps Previous component props
//  * @param {Object} nextProps Current component props
//  * @returns {boolean}
//  */
// const areEqual = (prevProps, nextProps) => {
//   let isEqual = true;
//
//   if (Object.keys(nextProps).length !== Object.keys(prevProps).length) {
//     return false;
//   }
//
//   for (let i = 0; i < Object.keys(nextProps).length; i++) {
//     const prop = Object.keys(nextProps)[i];
//
//     if (typeof nextProps[prop] === "function") {
//       continue;
//     } else if (prop === "values") {
//       isEqual =
//         JSON.stringify(prevProps[prop]) === JSON.stringify(nextProps[prop]);
//     } else {
//       isEqual = prevProps[prop] === nextProps[prop];
//     }
//
//     if (!isEqual) {
//       break;
//     }
//   }
//
//   return isEqual;
// };

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  values: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  animatePlaceholder: PropTypes.bool,

  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,

  errorText: PropTypes.string,
  warningText: PropTypes.string,
  error: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default memo(Select);
